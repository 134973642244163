export default (links, locale) => links.map((component) => {
    const comp = component.link || component.mainlink;

    return {
        _uid: component._uid,
        text: component.label,
        link: comp ? usePrepareStoryblokLink(comp, locale.value) : null,
        content_side: component.content_side,
        content_main: component.content_main,
    };
});
